<template>
    <div class="page">

        <v-row>
            <v-col cols="10" offset="1">
                <v-row class="hidden-xs-only">
                    <v-col>
                        <BreadCrumbs step="LOCATIONTIME"></BreadCrumbs>
                    </v-col>
                </v-row>

                <v-row class="justify-space-between">
                    <v-col md="7" xs="12">

                        <TitleText class="display-row" title="Uw vestiging"
                            :text="currentEstablishmentAddress">
                        </TitleText>

                        <v-divider></v-divider>

                        <v-radio-group v-model="currentEstablishmentId">
                            <v-radio
                                v-for="establishment in establishmentsSelect"
                                :key="establishment.id"
                                :value="establishment.id"
                            >
                                <template v-slot:label v-class="establishment__container"
                                >{{ establishment.label }}
                                <span class="establishment__label">
                                    (Eerst volgende plandatum: {{establishment.firstAvailableDate}})
                                </span>
                                </template
                                >
                            </v-radio>
                        </v-radio-group>

                        <v-row>
                            <v-col cols="6">
                                <v-btn class="rounded-lg text-uppercase darkgrey--text"
                                       color="secondary"
                                       type="button"
                                       height="41px"
                                       @click="previousPage()">
                                    Ga terug
                                </v-btn>
                            </v-col>

                            <v-col cols="6" align="right"
                                   v-if="currentEstablishmentId">
                                <v-btn class="rounded-lg text-uppercase"
                                       color="primary"
                                       type="button"
                                       height="41px"
                                       @click="nextPage()">
                                    Ga verder
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-col>

                    <Sidebar
                        :car="{show: true, toggle: false}"
                        :maintenance="{show: true, toggle: false}"
                        :location="{show: true, toggle: true, showToggle: false}"
                        class="hidden-xs-only"
                    ></Sidebar>
                </v-row>
            </v-col>
        </v-row>
        <v-overlay :value="this.loaderShown" color="white" class="loader darkblue--text">
            <div id="hourglass" v-html="svg" style="margin-left: 30%;"></div>

            <br/>
            <h6 class="text-uppercase">Beschikbaarheid controleren</h6>
            <br/>

            <div class="ml-15 insurances">
                <v-list-item>
                    <v-list-item-content>
                        <v-list-item-title class="darkblue--text">
                            <v-icon large color="darkblue">mdi-check</v-icon>
                            Data ophalen
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </div>
        </v-overlay>
    </div>
</template>

<script>

import BreadCrumbs from "@/components/general/BreadCrumbs";
import Sidebar from "@/components/maintenance/Sidebar";
import TitleText from "@/components/general/TitelText";
import {mapGetters} from "vuex";

const loaderSvg = require("@/assets/hourglass.svg?data");

export default {
    "name": "Establishments",
    "components": {
        BreadCrumbs,
        Sidebar,
        TitleText
    },
    "computed": {
        ...mapGetters({
            "getEstablishmentsByMake": "establishmentInfo/getEstablishmentsByMake"
        }),
        carMake () {
            return this.$store.state.car.currentCarInfo.make;
        },
        "currentEstablishmentId": {
            get () {
                return this.$store.state.car.currentEstablishmentId;
            },
            set (id) {
                this.updatePathValue(
                    "currentEstablishmentId",
                    id
                );
            }
        },
        currentEstablishmentAddress () {
            const establishment = this.establishments
                .find((est) => est.Id === this.currentEstablishmentId);
            if (establishment) {
                const name = establishment.Name
                    .replace(
                        "VOLVO",
                        "Volvo"
                    )
                    .replace(
                        "B.V.",
                        ""
                    ).replace(
                        "BV",
                        ""
                    )
                    .trim();
                return `${name}<br />`;
            }
            return "";
        },
        establishments () {
            return this.$store.state.establishmentInfo.Establishments;
        },
        establishmentsSelect () {
            return this.establishments
                .slice()
                .sort((a, b) => a.Name.localeCompare(b.Name))
                .map((establishment) => ({
                    "id": establishment.Id,
                    "label": establishment.Name.replace(
                        "VOLVO",
                        ""
                    ).replace(
                        "B.V.",
                        ""
                    ).replace(
                        "BV",
                        ""
                    ).trim(),
                    "firstAvailableDate": establishment.FirstAvailableDate
                }));
        },
        appointmentType () {
            return this.$store.state.car.appointmentType;
        },
        currentContactInfo () {
            return this.$store.state.car.currentContactInfo;
        }
    },
    data () {
        return {
            "svg": atob(loaderSvg.default.replace(
                "data:image/svg+xml;base64,",
                ""
            )),
            "loaderShown": false,
            "employee_assets": process.env.VUE_APP_ASSETS_EMPLOYEES,
            "mechanicButtonHeight": "215",
            "mechanicButtonWidth": "175",
            "establishmentValue": null,
            "showMechanicLoader": false
        };
    },
    async created () {
        this.loaderShown = true;
        const carEstablishment = this.$store.state.car.currentCarInfo.establishment;
        if (carEstablishment) {
            const establishment = this.establishments
                .find((est) => (est.City.toLowerCase() === carEstablishment.toLowerCase()));
            if (establishment) {
                this.currentEstablishmentId = establishment.Id;
            }
        }
        await this.$store.dispatch(
            "establishmentInfo/getFirstAvailablePlanDate",
            {
                "establishments": this.$store.state.establishmentInfo.Establishments
            }
        );
        this.loaderShown = false;
    },
    "methods": {
        getEstablishments (make) {
            return this.getEstablishmentsByMake(make);
        },
        updatePathValue (object, value) {
            this.$store.dispatch(
                "car/updatePathValue",
                {
                    object,
                    value
                }
            );
        },
        previousPage () {
            const windowRepairProduct = this.$store.state.car.chosenProducts.find((item) => item.code.trim().toLowerCase() === "voorruitster");
            const windowReplaceProduct = this.$store.state.car.chosenProducts.find((item) => item.code.trim().toLowerCase() === "voorruitvervanging");

            if (typeof windowRepairProduct !== "undefined" || typeof windowReplaceProduct !== "undefined") {
                if (typeof (this.nextActionProduct) !== "undefined") {
                    this.$router.push({"name": "ActionPage"});
                } else {
                    this.$router.push({"name": "PhotosDamageRecovery"});
                }
            } else {
                this.$router.push({"name": "OptionWindowDamageRecovery"});
            }
        },
        nextPage () {
            this.$router.push({"name": "Wait"});
        }
    },
    "watch": {
        currentEstablishmentId (establishmentId) {
            if (!establishmentId) {
                return;
            }

            const establishments = this.establishments.find((est) => (est.Id === establishmentId));
            this.updatePathValue(
                "Appointment.Establishment.Name",
                establishments.Name
            );
            this.updatePathValue(
                "Appointment.Establishment.EmailAddresses",
                establishments.EmailAddresses
            );
        }
    }
};
</script>

<style lang="scss">
.multiplerow-flex {
    display: flex;
    align-items: center;
    justify-content: center;
    /* You can set flex-wrap and
       flex-direction individually */
    flex-direction: row;
    flex-wrap: wrap;
    /* Or do it all in one line
      with flex flow */
    flex-flow: row wrap;
    /* tweak where items line
       up on the row
       valid values are: flex-start,
       flex-end, space-between,
       space-around, stretch */
    align-content: flex-end;
}

@keyframes rotateHourglass {
    /* 95% to fill the hourglass, 5% to rotate the shape around its center  */
    25% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(180deg);
    }
}

#hourglass svg {
    animation: rotateHourglass 1.5s ease-in-out;
    transform-origin: 50% 50%;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.insurances .v-list-item {
    opacity: 0;
    animation-name: fadeIn;
    animation-duration: 2s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;

    &:nth-child(1) {
        animation-delay: 0s;
    }
}

.v-btn__content {
    flex-direction: column;
}

.transparent-overlay {
    background: rgba(0,0,0,0.5);
}

.v-btn-toggle>.v-btn.v-btn--active .mechanic-name  {
    background-color: white;
    color: #395448;
}

.v-btn-toggle>.v-btn.v-btn--active .mechanic-plandate  {
    background-color: #395448;
    color: #fff;
}

.v-btn-toggle>.v-btn .mechanic-img  {
    opacity: 0.3;
}

.v-btn-toggle>.v-btn.v-btn--active .mechanic-img  {
    border: 4px #F9B000 solid;
    opacity: 1;
}
span.establishment__label {
    margin-left: auto;
    font-size: 14px;
}
@media (max-width: 512px) {
    .v-input--selection-controls .v-input__slot>.v-label, .v-input--selection-controls .v-radio>.v-label {
        display: block !important;
    }

    span.establishment__label {
        width: 100%;
        display: block;
    }
}

@media only screen and (max-width: 600px) {
    #hourglass {
        margin-left: 40%;
    }
    #hourglass svg {
        width: 50%;
    }
}

.display-row > div {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.display-row p.dark--text {
    line-height: 2;
    margin-bottom: unset !important;
    margin-left: 2em;
}
</style>
